<template>
  <div>
    <b-form @submit.prevent>
      <validation-observer ref="form">
        <b-row>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Código"
              label-for="codigo"
            >
              <validation-provider
                #default="{ errors }"
                name="Código"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="codigo"
                    v-model="documentosContrato.codigo"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalDocumentosContrato"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="8"
            lg="8"
          >
            <b-form-group
              label="Descrição"
              label-for="h-descricao"
            >
              <validation-provider
                #default="{ errors }"
                name="Descrição"
                rules="required"
              >
                <b-form-input
                  id="h-descricao"
                  v-model="documentosContrato.descricao"
                  placeholder="Descrição"
                  maxlength="50"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="1"
            lg="1"
          >
            <b-form-group
              label="Inactivo"
              label-for="inactivo"
              label-class="mb-1"
            >
              <b-form-checkbox
                id="inactivo"
                v-model="documentosContrato.inactivo"
                value="1"
              />

            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>

    <b-modal
      ref="modalDocumentosContrato"
      hide-footer
      size="xl"
    >
      <selecionarDocumentoContrato @documentosContratoSelecionado="selectDocumentosContrato" />
    </b-modal>
  </div>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroupPrepend, BInputGroup, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import router from '@/router'
import selecionarDocumentoContrato from '../../../Listagens/DocumentosContratos/SelecionarDocumentoContratos.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    selecionarDocumentoContrato,
  },
  directives: {
    Ripple,
  },
  props: {
    seriesC: Object,
    documentosContrato: Object,
  },

  data() {
    return {
      required,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },

    }
  },

  created() {
    localize('pt', pt)
    if (router.currentRoute.params.id !== undefined) {
      this.getOneDocumentoContrato()
    }
  },
  methods: {

    getOneDocumentoContrato() {
      const documentoContratoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/documentosContrato/${documentoContratoId}`

      axios.get(apiUrl)
        .then(res => { this.selectDocumentosContrato(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    selectDocumentosContrato(row) {
      this.seriesC.series = []
      this.documentosContrato.id = row.id
      this.documentosContrato.codigo = row.codigo
      this.documentosContrato.descricao = row.descricao
      this.documentosContrato.inactivo = row.inactivo

      axios.get(`/api/v1/seriesContrato/documentosContrato/${row.id}/complete`)
        .then(res => {
          const resultado = res.data
          this.seriesC.series = resultado.map(item => ({ ...item }))
        })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.hideModalDocumentosContrato()
    },

    switchLoc() {
      this.$refs.form.validate().then(valid => {
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')
        } else {
          this.saveDocumentosContrato()
        }
      })
    },

    saveDocumentosContrato() {
      axios.put(`/api/v1/documentosContrato/${this.documentosContrato.id}`, this.documentosContrato)
        .then(this.showToast('success', 'CheckIcon'))
      this.getDocumentosContrato()
    },

    showModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.show()
    },
    hideModalDocumentosContrato() {
      this.$refs.modalDocumentosContrato.hide()
    },
    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
